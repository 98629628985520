<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/06/Shark-XH-rev.1-210607-1536x1044.png"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->

                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">
                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        Fully automatic terminating
                                        machine for IDC Connector
                                    </p>
                                    <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                        MAIN FEATURES
                                    </h1>
                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        – Feeding system: up to 3 different wires,
                                        wire length freely programmable (5 wires optional) <br>
                                        – Parallel and crossing wires as well as loops or one-side wire
                                        terminations are producible<br>
                                        – Min wire length = 195 mm (Loops),
                                        80 mm (Free wire)
                                        <br>– Max. wire length = 3000 mm (Loops), 1500 mm (Free wire)
                                        <br>– Simultaneous processing connectors with different number
                                        of ways, coding versions etc.
                                        <br>– Max Production configuration:
                                        54 contact positions for Rast 2,5
                                        28 contact positions for Rast 5
                                        <br>– Connector loading system without machine stop
                                        <br>– Terminating Unit with fine adjustment termination height
                                        <br>– Test device for the correct wire position in the connector
                                        <br>– Continuity Test
                                        <br>– Coding station, gull automatic setting blade
                                        <br>– Test device of the correct key cutting
                                        <br>– Selective destroying unit of bad harness
                                        <br>– User friendly HMI based on Windows O.S.
                                        <br>– Internet connection for remote assistance
                                        <br>– Conveyor guide for good harnesses
                                        <br>– Expulsion of bad harnesses
                                        <br>– CE conformity
                                    </p>
                                </div>
                                <br />
                                <div class="mt-4">
                                    <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                        CRIMP MODULE
                                    </h1>
                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        – Max 4 Crimping units, with CFA
                                        <br>– Wire Feeding up to 3 different wires, length freely
                                        programmable (5 wires optional)
                                        <br>– Min. wire length = 80 mm (Free / Crimped / Stripped wires)
                                        <br>– Max. wire length = 1500 mm (Free / Crimped / Stripped
                                        wires)
                                        <br>– Carrier strip chopper
                                        <br>– Paper take-up
                                        <br>– Sleeve Insertion Unit (optional)
                                        <br>– Seal Station Unit (optional)
                                        <br>– Dimensions: 1300 x 1800 x h 2450 mm (safety shield open)
                                        <br>– Weight: approx. 1500 kg
                                    </p>
                                </div>
                                <br />



                                <p style="font-size: 14px; color: black; font-weight: 400">
                                    All data and measures are indicative and can be changed without notice. Certain
                                    types of extremely hard, tough wires may not be able to be processed even if written
                                    within the indicated ranges. In case of doubt, KM Corporate is willing to produce
                                    samples of your wire. </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br />
                        <br />
                        <br />
                        <div class="row">
                            <div class="col-5"></div>
                            <div class="col-7">
                                <button @click="file()" class="btn btn-primary" style="width:100%"><i
                                        class="fa fa-download"></i> Download The Technical Sheet</button>
                            </div>
                        </div>
                        <br><br><br>
                        <div class="row">
                            <h1 style="color: black; font-weight: 800; margin-left: 40px" class="font-size-20 mb-3">
                                Gallery

                            </h1>

                        </div>
                        <br />

                        <div class="row" style="margin-left:150px;">

                            <vue-picture-swipe :items="items"></vue-picture-swipe>
                        </div>
                        <!-- end row -->

                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {

        Header,
        Footer,


    },
    data() {
        return {
            items: [{
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/Shark-XH-dallalto-rev.1-210610-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/Shark-XH-dallalto-rev.1-210610-150x150.jpg',
                w: 1200,
                h: 900,
                alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/SHARK-XH-HMI-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/SHARK-XH-HMI-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Shark-25XH-30-wire-Footprint-1-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Shark-25XH-30-wire-Footprint-1-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/Shark-25XH-50-wire-Footprint-2-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/Shark-25XH-50-wire-Footprint-2-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-024-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-024-150x150.jpg',
                w: 1200,
                h: 900
            },

            ]
        };

    },

    methods: {
        file() {
            window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Shark-Xh_Rev-19-01-Low.pdf";
  
        }
    }
};
</script>